var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["main-page", _vm.classes] },
    [
      _c("image-gallery-block", {
        attrs: { id: "about", "initial-tab": "Архитектура" },
      }),
      _c("location-block", {
        attrs: {
          id: "location",
          title: "Местоположение",
          "initial-tab": "Удобство",
        },
      }),
      _c("plan-types-block", { ref: "plansBlock", attrs: { id: "plans" } }),
      false
        ? _c("callback-block", {
            attrs: {
              "id-formblock": "design-block__gift",
              theme: "black",
              title: "Ваш дизайн-проект - в подарок!",
              subtitle: "При покупке до 20.07.2020",
              image: require("../assets/images/forms/form-block-right.png"),
            },
          })
        : _vm._e(),
      _c("mortgage-block"),
      false ? _c("specials-block") : _vm._e(),
      false
        ? _c("callback-block", {
            staticClass: "fix-price",
            attrs: {
              id: "fix",
              theme: "black",
              title: "Зафиксируй цену до 90 дней",
              subtitle: "При покупке до 20.07.2020",
            },
          })
        : _vm._e(),
      _c("slider-news", { attrs: { title: "Новости", navigation: true } }),
      _c("contacts-block", {
        attrs: {
          "building-address": _vm.address,
          "ofiice-address": _vm.office,
          "office-work-time": _vm.workTime,
        },
      }),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }