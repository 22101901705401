<template>
  <div :class="['main-page', classes]">
    <image-gallery-block id="about" initial-tab="Архитектура" />
    <location-block
      id="location"
      title="Местоположение"
      initial-tab="Удобство"
    />
    <plan-types-block id="plans" ref="plansBlock" />
    <callback-block
      v-if="false"
      id-formblock="design-block__gift"
      theme="black"
      title="Ваш дизайн-проект - в подарок!"
      subtitle="При покупке до 20.07.2020"
      :image="require('../assets/images/forms/form-block-right.png')"
    />

    <mortgage-block />
    <specials-block v-if="false" />
    <callback-block
      v-if="false"
      id="fix"
      class="fix-price"
      theme="black"
      title="Зафиксируй цену до 90 дней"
      subtitle="При покупке до 20.07.2020"
    />
    <slider-news title="Новости" :navigation="true" />
    <contacts-block
      :building-address="address"
      :ofiice-address="office"
      :office-work-time="workTime"
    />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ImageGalleryBlock from "@/components/blocks/ImageGalleryBlock";
// import FeaturesBlock from "@/components/blocks/FeaturesBlock";
import MortgageBlock from "@/components/blocks/MortgageBlock";
import LocationBlock from "@/components/blocks/LocationBlock";
import ContactsBlock from "@/components/blocks/ContactsBlock";
import SpecialsBlock from "@/components/blocks/SpecialsBlock";
import PlanTypesBlock from "@/components/blocks/PlanTypesBlock";
import SliderNews from "@/components/blocks/SliderNews";
// import CrisisProtectBlock from "@/components/blocks/CrisisProtectBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import ProgressBlock from "@/components/blocks/ProgressBlock";

export default {
  name: "MainPage",
  components: {
    // CrisisProtectBlock,
    PlanTypesBlock,
    SpecialsBlock,
    MortgageBlock,
    // FeaturesBlock,
    ImageGalleryBlock,
    LocationBlock,
    ContactsBlock,
    SliderNews,
    CallbackBlock,
    ProgressBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Комфортные апартаменты бизнес-класса с отделкой в престижном районе Москвы. ",
      },
    ],
  },
  computed: {
    ...mapState({
      isFixed: (state) => state.isFixed,
      building: (state) => state.building,
      storage: (state) => state.storage,
      workTime: (state) => state.workTime,
      dateMortgage: (state) => state.dateMortgage,
    }),
    classes() {
      return {
        "header--fixed": this.isFixed,
      };
    },
    address() {
      if (!this.storage || !this.storage.addresses) {
        return "";
      }
      return (
        '<p class="marginss">' +
        this.storage.addresses.addr_list[0].addr_location +
        "</p>"
      );
    },
    office() {
      if (!this.storage || !this.storage.addresses) {
        return "";
      }
      return "<p>" + this.storage.addresses.addr_list[1].addr_location + "</p>";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      setTimeout(() => {
        vm.resetWindow();
      }, 900);
    });
  },
  mounted() {
    this.showPopup();
  },
  methods: {
    resetWindow() {
      window.dispatchEvent(new Event("resize"));
    },
    showPopup() {
      const lsName = "popup";
      const popups = {
        0: () =>
          this.showVideoPromoDialog({
            title: "Кешбэк 20% <br>для юридических лиц",
            img: {
              default: require("@/assets/images/promo/popup_mob.jpg"),
              sources: [
                {
                  jpg: require("@/assets/images/promo/popup.webp"),
                  media: "(min-width: 992px)",
                },
                {
                  jpg: require("@/assets/images/promo/popup.jpg"),
                  media: "(min-width: 992px)",
                },
                {
                  jpg: require("@/assets/images/promo/popup_mob.webp"),
                  media: "(min-width: 0px)",
                },
              ],
            },
          }),
        1: () =>
          this.showVideoPromoDialog({
            class: "variant-2",
            title: "Cкидки до 35%<br>на машино-места",
            description: "в сентябре",
            img: {
              default: require("@/assets/images/promo/popup-3_mob.jpg"),
              sources: [
                {
                  jpg: require("@/assets/images/promo/popup-3.webp"),
                  media: "(min-width: 992px)",
                },
                {
                  jpg: require("@/assets/images/promo/popup-3.jpg"),
                  media: "(min-width: 992px)",
                },
                {
                  jpg: require("@/assets/images/promo/popup-3_mob.webp"),
                  media: "(min-width: 0px)",
                },
              ],
            },
          }),
      };
      let id = +window.localStorage.getItem(lsName);
      if (!popups[id]) {
        id = 0;
      }
      popups[id]();
      id++;
      window.localStorage.setItem(lsName, id);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  overflow: hidden;
}

.fix-price {
  padding-top: 25px;

  @media screen and (min-width: $screen-mini) {
    padding-top: 0;
  }

  @media screen and (min-width: $screen-mini) {
    display: none;
  }
}
</style>
